import moment from "moment"


export const CommandTemplate = [
  { type: 'Edit', buttonOption: { cssClass: 'e-flat', iconCss: 'e-edit e-icons' } },
  { type: 'Delete', buttonOption: { cssClass: 'e-flat text-danger', iconCss: 'e-delete e-icons' } }
];

export const ConfirmDialogStyles = {
  classNames: {
    confirmButton: 'btn btn-sm confirm-button',
    cancelButton: 'btn btn-sm cancel-button',
  }
}

export const SearchGridToolbar = ['Search'];

export const LoadingSpinner = () => {
  return (
    <div className="text-center">
      <i className="fas fa-spinner fa-spin fa-2x"></i><br />Loading...
    </div>
  );

}


export const toIsoDateTime = (value) => {
  if (value && value !== "") {
    let converted;
    if (value.match(/(\d\d)\/(\d\d)\/(\d\d\d\d) (\d\d):(\d\d)/g))
      converted = moment(value, "DD/MM/YYYY HH:mm").format('YYYY-MM-DDTHH:mm:ssZ');
    else
      converted = moment(value, "YYYY-MM-DDTHH:mm:ssZ").format("YYYY-MM-DDTHH:mm:ssZ");
    return converted;
  }
  else return "";
}

export const ChangeFormat = (value, format) => {
  if (!value || value === "")
    return "";
  else
    return moment(value).format(format);
}

export const getImage = (Content) => {
  return "data:image/png;base64," + Content;
}