import { DropDownListComponent } from "@syncfusion/ej2-react-dropdowns";
import React, { Component } from "react";
import { Button, Input } from "reactstrap";
import { getAuthToken, IsAuthenticated } from "../helpers/authentication";
import {
  getEditId, GetProvinceList,
  GetUserTypes, setEditId
} from "../helpers/Lookup";
import ThrobbleHelper from "../helpers/ThrobbleHelper";
import { showToast } from "../helpers/ToastHelper";
import { fieldRequired } from "../helpers/validation";

export default class UserProfile extends Component {
  constructor(props) {
    super(props);

    this.state = {
      // UserTypeList: ,
      SupplierList: [],
      EventList: [],
      EditId: 0,
      Id: 0,
      EventId: 0,
      UserId: "",
      UserType: 10,
      FirstName: "",
      LastName: "",
      EmailAddress: "",
      Mobile: "",
      CompanyName: "",
      Designation: "",
      Province: 0,
      Password: "",
      SupplierId: 0,
      QRImage: "",
      isPasswordValid: true,
      loading: true,
    };

    this.SaveData = this.SaveData.bind(this);
    this.sendWelcomeEmail = this.sendWelcomeEmail.bind(this);
  }

  componentDidMount() {
    IsAuthenticated().then((res) => {
      if (res) {
        ThrobbleHelper.toggleThrobble(true, "Getting user details");
        document.title = "Est Africa :: User Profile";
        this.loadData();
      }
    }).catch(() => window.location.href = "/login");
  }

  async loadData() {
    const UserId = getEditId();
    const bearer = "Bearer " + getAuthToken();

    try {
      const response = await fetch("/api/userdata/" + UserId, {
        method: "GET",
        headers: {
          Authorization: bearer,
          ContentType: "application/json",
        },
      });

      if (response.ok) {
        const data = await response.json();
        this.setState({
          EditId: data.id,
          Id: data.id,
          EventId: data.eventId,
          UserType: data.userType,
          FirstName: data.firstName,
          LastName: data.lastName,
          EmailAddress: data.emailAddress,
          Password: "",
          SupplierId: data.supplierId,
          QRImage: data.QRImage,
          Mobile: data.mobile,
          CompanyName: data.companyName,
          Designation: data.designation,
          Province: data.province,
          LockedOut: data.lockedOut,
          loading: false,
        });
      } else {
        console.log(response.status + ": " + response.statusText);
        // this.setState({ loading: false });
      }
    } catch (e) {
      console.error(e);
      // this.setState({ loading: false });
    }

    try {
      const SupplierResponse = await fetch("/api/suppliers", {
        method: 'GET',
        withCredentials: true,
        credentials: 'include',
        headers: {
          'Authorization': bearer,
          'Content-Type': 'application/json'
        }
      });

      if (SupplierResponse.ok) {
        const data = await SupplierResponse.json();
        // const SupplierList = [{ id: 0, name: "- All Suppliers -" }, ...data];
        this.setState({ SupplierList: data, loading: false });
        ThrobbleHelper.toggleThrobble(false);
      } else {
        console.log(SupplierResponse.status + ": " + SupplierResponse.statusText);
        if (SupplierResponse.status === 401)
          window.location.href = "/login";

        //this.setState({ loading: false });
        ThrobbleHelper.toggleThrobble(false);
      }
    }
    catch (e) {
      console.error("Supplier:", e)
      this.setState({ loading: false });
    }

    try {
      const EventResponse = await fetch("/api/events", {
        method: 'GET',
        withCredentials: true,
        credentials: 'include',
        headers: {
          'Authorization': bearer,
          'Content-Type': 'application/json'
        }
      });

      if (EventResponse.ok) {
        const data = await EventResponse.json();
        console.log(data);
        this.setState({ EventList: data, loading: false });
        ThrobbleHelper.toggleThrobble(false);
      } else {
        console.log(EventResponse.status + ": " + EventResponse.statusText);
        if (EventResponse.status === 401)
          window.location.href = "/login";

        this.setState({ loading: false });
        ThrobbleHelper.toggleThrobble(false);
      }
    }
    catch (e) {
      console.error("Events:", e)
      this.setState({ loading: false });
    }
  }

  renderUserForm(state) {
    const ProvinceList = GetProvinceList();
    const UserTypeList = GetUserTypes();
    return (
      <>
        <div className="row">
          <div className="col-md-6 mb-3">
            <label>
              First Name
              <span className="ErrorText ms-1">*</span>
              <span id="errFirstname" className="ErrorText"></span>
            </label>
            <Input type="text" bsSize={"sm"} value={state.FirstName} onChange={(e) => this.setState({ FirstName: e.target.value }, () => fieldRequired(this.state.FirstName, "errFirstname", " Required"))} />
          </div>
          <div className="col-md-6 mb-3">
            <label>
              Last Name
              <span className="ErrorText ms-1">*</span>{" "}
              <span id="errLastname" className="ErrorText "></span>
            </label>
            <Input type="text" bsSize={"sm"} value={state.LastName} onChange={(e) => this.setState({ LastName: e.target.value }, () => fieldRequired(this.state.LastName, "errLastname", " Required"))} />
          </div>
          <div className="col-md-6 mb-3">
            <label>
              Email Address
              <span className="ErrorText ms-1">*</span>{" "}
              <span id="errEmail" className="ErrorText "></span>
            </label>
            <Input type="email" bsSize={"sm"} value={state.EmailAddress} onChange={(e) => this.setState({ EmailAddress: e.target.value }, () => fieldRequired(this.state.EmailAddress, "errEmail", " Required"))} />
          </div>
          <div className="col-md-6 mb-3">
            <label>
              Mobile
              <span className="ErrorText ms-1">*</span>{" "}
              <span id="errMobile" className="ErrorText"></span>
            </label>
            <Input type="text" bsSize={"sm"} value={state.Mobile} onChange={(e) => this.setState({ Mobile: e.target.value }, () => fieldRequired(this.state.Mobile, "errMobile", " Required"))} />
          </div>
          <div className="col-md-4 mb-3">
            <label>Province</label>
            <DropDownListComponent dataSource={ProvinceList} allowFiltering={true} fields={{ text: "text", value: "value" }} value={state.Province} placeholder="- Select Province -" select={(e) => { this.setState({ Province: e.itemData.value }); }} />
          </div>

          <div className="col-md-4 mb-3">
            <label>
              User Type
              <span className="ErrorText ms-1">*</span>{" "}
              <span id="errUserType" className="ErrorText"></span>
            </label>
            <DropDownListComponent dataSource={UserTypeList} fields={{ text: "text", value: "value" }} value={state.UserType} placeholder="- Select User Type -" select={(e) => this.setState({ UserType: e.itemData.value }, () => fieldRequired(e.itemData.value, "errUserType", "Required"))} />
          </div>
          <div className="col-md-4 mb-3" hidden={state.UserType !== 10}>
            <label>
              User Supplier
              <span className="ErrorText ms-1">*</span>{" "}
              <span id="errSupplier" className="ErrorText"></span>
            </label>
            <DropDownListComponent dataSource={state.SupplierList} allowFiltering={true} fields={{ value: "id", text: "supplierWithEvent" }} value={state.SupplierId} placeholder="- Select Supplier -" select={(e) => this.setState({ SupplierId: e.itemData.id }, () => fieldRequired(e.itemData.id, "errSupplier", " Required"))} />
          </div>
          <div className="col-md-4 mb-3">
            <label>
              Event
              <span className="ErrorText ms-1">*</span>{" "}
              <span id="errSupplier" className="ErrorText"></span>
            </label>
            <DropDownListComponent dataSource={state.EventList} fields={{ value: "id", text: "name" }} value={state.EventId} placeholder="- Select Event -" select={(e) => this.setState({ EventId: e.itemData.id }, () => fieldRequired(e.itemData.id, "errSupplier", " Required"))} />
          </div>
        </div>
        <div className="row">
          <div className="col-md-6">
            <label>
              Password
              <span className="ErrorText ms-1" hidden={this.state.EditId > 0}>*</span>
              <span id="errPassword" className="ErrorText" />
            </label>
            <Input type="password" bsSize={"sm"} onChange={(e) => this.setState({ Password: e.target.value, isPasswordValid: e.target.value === "" || e.target.value === this.state.ConfirmPassword, })} />

          </div>
          <div className="col-md-6">
            <label>Confirm Password</label>
            <Input type="password" bsSize={"sm"} onChange={(e) => this.setState({ ConfirmPassword: e.target.value, isPasswordValid: e.target.value === this.state.Password, })} invalid={!state.isPasswordValid} />
          </div>

          <div className="col-12  mb-3">
            <div className={"alert alert-info d-flex pt-1 pb-2 mt-1 " + (this.state.EditId === 0 && "d-none")}>
              <i className="far fa-circle-exclamation pt-2 me-2"></i>
              <span className="align-self-end small">To retain the current password for an existing user, leave the assword field empty</span>
            </div>
          </div>

          <div className="col-md-12 mb-3">
            <Input className="" type="checkbox" defaultChecked={state.LockedOut} onChange={(e) => this.setState({ LockedOut: e.target.checked })} />{" "}Locked Out</div>
        </div>
        <hr />
        <div className="text-end ">
          <Button size="sm" color="primary" className="me-2" hidden={state.Id === 0} onClick={this.sendWelcomeEmail}><i className="far fa-envelope me-2"></i>Send Welcome Message</Button>
          <Button size="sm" color="success" onClick={this.SaveData}>
            <i className="far fa-check-circle me-2"></i> Save{" "}
          </Button>
        </div>
      </>
    );
  }

  async sendWelcomeEmail(evt) {
    evt.stopPropagation();

    const bearer = "Bearer " + getAuthToken();
    try {
      ThrobbleHelper.toggleThrobble(true, "Sending welcome message");
      const response = await fetch("api/userdata/SendWelcome/" + this.state.Id, {
        method: "PUT",
        headers: {
          Authorization: bearer,
          "Content-Type": "application/json",
        }
      });

      if (response.ok) {
        ThrobbleHelper.toggleThrobble(false);
        showToast("success", "Welcome Message", "Welcome message was successfully sent!");
      } else {
        if (response.status === 401)
          window.location.href = "/login";

        ThrobbleHelper.toggleThrobble(false);
        showToast("error", "Welcome Message", "There was an error sending welcome message!");
      }
    } catch (e) {
      console.error(e);
      showToast("error", "Welcome Message", "There was an error sending welcome message!");
      ThrobbleHelper.toggleThrobble(false);
    }

  }

  async SaveData(evt) {
    evt.stopPropagation();
    const bearer = "Bearer " + getAuthToken();
    try {
      let isValid = true;
      isValid &= fieldRequired(this.state.FirstName, "errFirstname", " Required");
      isValid &= fieldRequired(this.state.LastName, "errLastname", " Required");
      isValid &= fieldRequired(this.state.EmailAddress, "errEmail", " Required");
      isValid &= fieldRequired(this.state.Mobile, "errMobile", " Required");
      isValid &= fieldRequired(this.state.UserType, "errUserType", " Required");

      if (this.state.UserType === 10)
        isValid &= fieldRequired(this.state.SupplierId, "errSupplier", " Required");

      if (this.state.EditId === 0)
        isValid &= fieldRequired(this.state.Password, "errPassword", " Required");

      if (isValid && this.state.isPasswordValid) {
        ThrobbleHelper.toggleThrobble(true, "Saving user data");
        let data = {
          Id: this.state.Id,
          EventId: this.state.EventId,
          UserType: this.state.UserType,
          FirstName: this.state.FirstName,
          LastName: this.state.LastName,
          EmailAddress: this.state.EmailAddress,
          Mobile: this.state.Mobile,
          Province: this.state.Province,
          Password: this.state.Password,
          LockedOut: this.state.LockedOut,
          SupplierId: this.state.SupplierId,
        };

        const response = await fetch("/api/userdata", {
          method: "POST",
          headers: {
            Authorization: bearer,
            "Content-Type": "application/json",
          },
          body: JSON.stringify(data),
        });

        if (response.ok) {
          const EditId = await response.json();
          setEditId(EditId);
          showToast("success", "User Profile", "User was successfully saved!");
          this.loadData();
        } else {
          console.log(response.status + ": " + response.statusText);
          if (response.status === 401) window.location.href = "/login";
          else if (response.status === 409) {
            showToast(
              "error",
              "User Profile",
              "Email address already exist in the system"
            );
          } else
            showToast(
              "error",
              "User Profile",
              "There was an error saving user"
            );
        }
      }
    } catch (e) {
      console.error(e);
      showToast("error", "User Profile", "There was an error saving user");
    }
  }

  render() {
    const content = this.state.loading ? (
      <em>
      </em>
    ) : (
      this.renderUserForm(this.state)
    );
    return (
      <>
        <div className="container">
          <div className="row mb-4">
            <div className="col-md-6">
              <h1>User Profile</h1>
            </div>
            <div className="col-md-6 align-self-center text-end">
              <button
                className="btn btn-outline-dark btn-sm"
                onClick={(e) => (window.location.href = "/user-list")}
              >
                <i className="far fa-arrow-circle-left me-2"></i>Back to Users
              </button>
            </div>
          </div>
          <div className="my-3">{content}</div>
        </div>
      </>
    );
  }
}
