import React, { Component } from 'react';
import { IsAuthenticated } from '../helpers/authentication';
import ThrobbleHelper from '../helpers/ThrobbleHelper';

export class Home extends Component {
  static displayName = Home.name;

  constructor(props) {
    super(props);

    this.state = { loading: true };
  }

  componentDidMount() {
    IsAuthenticated().then(res =>
      this.setState({ loading: false })
    ).catch(() => window.location.href = "/login");;
    // if (!IsAuthenticated())
    //   window.location.href = "/login";
    // else
    //   this.setState({ loading: false })
  }

  render() {
    return (

      this.state.loading ? <></> :
        <div className='container mt-4'>
          <div className="row">
            <div className="col mb-3">
              <a href="/events" className='btn btn-purple w-100'>
                
                <i class="fa-solid fa-calendar-days fa-4x"></i>
                <br />
                EVENTS
              </a>
            </div>
            
            <div className="col mb-3">
              <a href="/suppliers" className='btn btn-purple w-100'>
                <i className='fas fa-store fa-4x'></i><br />
                SUPPLIERS
              </a>
            </div>
            <div className="col mb-3">
              <a href="/visitors" className='btn btn-purple w-100'>
                <i className="fa-solid fa-users fa-4x"></i> <br />
                VISITORS
              </a></div>
            <div className="col mb-3">
              <a href="/Visits-report" className='btn btn-purple w-100'>
                <i className="fas fa-rectangle-list fa-4x"></i><br />
                VISITS REPORT
              </a></div>
            <div className="col mb-3">
              <a href="/user-list" className='btn btn-purple w-100'>
                <i className='fas fa-user-gear fa-4x'></i><br />
                USERS
              </a></div>
            <div className="col mb-3">
              <a href="/pagecontent" className='btn btn-purple w-100'>
                <i className='fas fa-file-code fa-4x'></i><br />
                PAGE CONTENT
              </a>
            </div>
          </div>
          
        </div>
    );
  }
}
