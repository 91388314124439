/* eslint-disable eqeqeq */
export const fieldRequired = (data, errorContainer, errorMessage) => {
  if (data === '' || data === 0 || data === null || data === undefined || data == false) {
    document.getElementById(errorContainer).innerText = errorMessage;
    return false;
  }
  else {
    document.getElementById(errorContainer).innerText = "";
    return true;
  }
};

export const emailRequired = (data, errorContainer, errorMessage) => {
  const pattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;
  if (data === '' || data === 0 || data === null || data === undefined || data == false || !pattern.test(data)) {
    document.getElementById(errorContainer).innerText = errorMessage;
    return false;
  }
  else {
    document.getElementById(errorContainer).innerText = "";
    return true;
  }
};

