import React, { Component } from 'react';
import { Button } from 'reactstrap';
import { IsAuthenticated, setAuthToken } from '../helpers/authentication';
import { setIsPCLogin } from '../helpers/Lookup';
import ThrobbleHelper from '../helpers/ThrobbleHelper';
import { showToast } from '../helpers/ToastHelper';
import { fieldRequired } from '../helpers/validation';

export default class Login extends Component {

  constructor(props) {
    super(props);

    this.state = {
      Username: "",
      Password: ""
    }
    this.LoginUser = this.LoginUser.bind(this);
  }

  componentDidMount() {
    if (!IsAuthenticated()) {

    }

  }
  async LoginUser(evt) {
    evt.stopPropagation();

    let isValid = true;
    isValid &= fieldRequired(this.state.Username, "errUsername", " * required");
    isValid &= fieldRequired(this.state.Password, "errPassword", " * required");

    ThrobbleHelper.toggleThrobble(true, "Logging in")
    if (isValid) {

      try {

        const response = await fetch(`/api/login/AdminLogin?username=${this.state.Username}&password=${this.state.Password}`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json"
          }
        });

        if (response.ok) {
          const data = await response.json();
          setAuthToken(data.item1, data.item2);
          setIsPCLogin(data.item3);
          showToast("success", "Login", "Login is successful");
          setTimeout(() => {
            window.location.href = "/";
          }, 2000)
        }
        else {
          if (response.status === 500)
            showToast("info", "Login", "Your account has been locked out");
          else
            showToast("error", "Login", "Username/Password is invalid");
        }
      } catch (e) {
        console.error(e);
        showToast("error", "Login", "Username/Password is invalid");
      }

    }
  }

  render() {
    return (
      <div className='container mt-5 '>
        <div className="row">
          <div className="col-md-6 col-lg-4 mx-auto">
            <div className="mb-5">
              <img src="/assets/est-building.png" className="img-fluid w-100" alt="Temblor Admin" />
            </div>
            <div className="card">
              <div className="card-body">
                <h3 className='mb=3 text-uppercase'>Login</h3>
                <form>
                  <div className="mb-3">
                    <input type='text' className='form-control form-control-sm' placeholder='Username' onChange={e => this.setState({ Username: e.target.value }, () => fieldRequired(this.state.Username, "errUsername", " * required"))} />
                    <div id='errUsername' className='ErrorText'></div>
                  </div>
                  <div className="mb-3">
                    <input type='password' className='form-control form-control-sm' placeholder='Password' onChange={e => this.setState({ Password: e.target.value }, () => fieldRequired(this.state.Password, "errPassword", " * required"))} />
                    <div id="errPassword" className='ErrorText'></div>
                  </div>
                  <div className="text-end">
                    <Button color='dark' size='sm' outline onClick={this.LoginUser}>Login</Button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
        <ThrobbleHelper />
      </div>
    )
  }
}
