/* eslint-disable default-case */
import { ColumnDirective, ColumnsDirective, CommandColumn, Edit, Filter, GridComponent, Page, Sort, Toolbar } from '@syncfusion/ej2-react-grids';
import { ToastUtility } from '@syncfusion/ej2-react-notifications';
import { Inject } from '@syncfusion/ej2-react-richtexteditor';
import React, { Component } from 'react';
import { confirm } from "react-confirm-box";
import { Button, Card, CardBody, Col, Input, Modal, ModalBody, ModalFooter, ModalHeader, Row } from 'reactstrap';
import { getAuthToken, IsAuthenticated } from '../helpers/authentication';
import { GetProvinceName, GetUserTypeName, setEditId } from '../helpers/Lookup';
import ThrobbleHelper from '../helpers/ThrobbleHelper';
import { showToast } from '../helpers/ToastHelper';
import { fieldRequired } from '../helpers/validation';
import { CommandTemplate, ConfirmDialogStyles, SearchGridToolbar, LoadingSpinner } from '../helpers/Global';
import { DropDownListComponent } from '@syncfusion/ej2-react-dropdowns';

export class UserData extends Component {

  static grid = null;

  constructor(props) {
    super(props);

    this.state = {
      editData: [], loading: true, editModal: false,
      allEventData: [],
      SupplierList: [],
      SupplierFilter: 0,
      EventFilter: 0,
      showUserImport: false, showUserImportResult: false, FileData: "", ImportResult: null
    };
    this.toggle = this.toggle.bind(this);
    this.toggleUserImport = this.toggleUserImport.bind(this);
    this.toggleUserImportResult = this.toggleUserImportResult.bind(this);
    this.StartImport = this.StartImport.bind(this);
    this.handleImportFileChange = this.handleImportFileChange.bind(this);

  }

  toggleUserImport(evt) {
    evt.stopPropagation();
    this.setState({ showUserImport: !this.state.showUserImport });
  }

  toggleUserImportResult(evt) {
    evt.stopPropagation();
    this.setState({ showUserImportResult: !this.state.showUserImportResult });
  }


  toggle() {
    this.setState({
      editModal: !this.state.editModal
    });
  }

  componentDidMount() {
    IsAuthenticated().then((res) => {
      if (res) {
        document.title = " User Administration";
        ThrobbleHelper.toggleThrobble(true, "Getting Users");
        this.loadData();
      }
    }).catch(() => window.location.href = "/login");
  }

  editItem = (id) => {

    setEditId(id);
    window.location.href = "/user-profile"
  };

  deleteItem = async (id) => {

    const result = await confirm("Are you sure you want to delete this item?", ConfirmDialogStyles);
    if (result) {
      this.deleteData(id);
    }
  }

  onGridCommand = (args) => {
    switch (args.commandColumn.type) {
      case 'Edit':
        this.editItem(args.rowData.id);
        break;
      case 'Delete':
        this.deleteItem(args.rowData.id);
        break;
    }
  }

  renderUsers(Users) {
    //console.log("in render users")
     
    if (this.state.SupplierFilter > 0)
      Users = Users.filter(c => c.supplierId === this.state.SupplierFilter);
    if (this.state.EventFilter > 0)
      Users = Users.filter(c => c.eventId === this.state.EventFilter);

    return (
      <GridComponent dataSource={Users} commandClick={this.onGridCommand} allowFiltering={true} allowSorting={true} filterSettings={{ type: "Menu" }}
        allowPaging={true} pageSettings={{ pageSize: 25 }} ref={e => UserData.grid = e} toolbar={SearchGridToolbar}>
        <ColumnsDirective>
          <ColumnDirective field='firstName' headerText="First Name" />
          <ColumnDirective field='lastName' headerText="Last Name" />
          <ColumnDirective headerText="User Type" template={this.RenderUserType} />
          <ColumnDirective field='emailAddress' headerText="Email Address" />
          <ColumnDirective field='eventName' headerText="Event" />
          <ColumnDirective field='supplierName' headerText="Supplier" />
          <ColumnDirective width={70} template={this.RenderUserLock} />
          <ColumnDirective headerText='Actions' commands={CommandTemplate} />
        </ColumnsDirective>
        <Inject services={[Sort, Edit, Filter, Page, CommandColumn, Toolbar]} />
      </GridComponent>
    )
  }

  RenderUserLock(props) {
    //<div className='ms-3'>{props.lockedOut ? <i title='Locked' className='fas fa-lock text-danger'></i> : <i title='Unlocked' className='fas fa-lock-open text-success'></i>}</div>
    if (props?.lockedOut)
      return <i title='Locked' className='fas fa-lock text-danger'></i>
    else
      return <i title='Unlocked' className='fas fa-lock-open text-success'></i>;
  }


  RenderUserType(props) {
    if (props)
      return <>{GetUserTypeName(props.userType)}</>
    else
      return <></>;
  }

  RenderProvince(props) {
    return <>{GetProvinceName(props.province)}</>;
  }

  render() {

    let contents = this.state.loading ? LoadingSpinner : this.renderUsers(this.state.editData);
    return (
      <>
        <div className="container">
          <Row className='mb-4'>
            <Col xs={6}>
              <h1>User Data Administration</h1>
            </Col>
            <Col xs={6} className="text-end align-self-center">
              <Button color="dark" size="sm" className='me-2' onClick={this.toggleUserImport}><i className='far fa-upload me-2'></i>Import Users</Button>
              <Button color="primary" size="sm" data-recordid="0" onClick={() => this.editItem(0)}>Add New <i className="fas fa-plus-circle ms-2"></i></Button>
            </Col>
          </Row>


          <Row>
            <div className="col-12 mb-3">
              <Card>
                <CardBody>
                  <div className="row">
                    <div className="col-md-4">
                      <label>Filter By Event</label>
                      <DropDownListComponent fields={{ value: "id", text: "name" }} placeholder="- All Events -" allowFiltering={true} dataSource={this.state.allEventData} value={this.state.EventFilter} filterType='Contains' allowFiltering={true} select={e => this.setState({ EventFilter: e.itemData.id })} />
                    </div>
                    <div className="col-md-4">
                      <label>Filter By Supplier</label>
                      <DropDownListComponent fields={{ value: "id", text: "supplierWithEvent" }} allowFiltering={true} dataSource={this.state.SupplierList} value={this.state.SupplierFilter} filterType='Contains' allowFiltering={true} select={e => this.setState({ SupplierFilter: e.itemData.id })} />

                    </div>
                  </div>


                </CardBody>
              </Card>
            </div>
            <Col xs={12}>
              {contents}
            </Col>
          </Row>
        </div>

        <Modal isOpen={this.state.showUserImport} fade toggle={this.toggleUserImport}>
          <ModalHeader toggle={this.toggleUserImport} close={<button className="btn-close" onClick={this.toggleUserImport}></button>} >
            Import Visitors
          </ModalHeader>
          <ModalBody>

            <label>Import File <small className='text-muted'>(excel)</small>
              <span id="errFile" className='ErrorText' /></label>
            <Input type='file' accept='.xlsx, .xls' onChange={this.handleImportFileChange} />
          </ModalBody>
          <ModalFooter>
            <Button color='dark' size='sm' onClick={this.toggleUserImport}>Cancel <i className="far fa-times-circle ms-2"></i></Button>
            <Button color='success' size='sm' onClick={this.StartImport}>Import <i className="far fa-check-circle ms-2"></i></Button>
          </ModalFooter>
        </Modal>

        <Modal isOpen={this.state.showUserImportResult} fade toggle={this.toggleUserImportResult}>
          <ModalHeader toggle={this.toggleUserImportResult} close={<button className="btn-close" onClick={this.toggleUserImportResult}></button>} >
            Visitors Import Results
          </ModalHeader>
          <ModalBody>
            <Card>
              <CardBody>
                {this.state.ImportResult &&
                  <>
                    <i className='fas fa-check-circle me-2 text-success'></i>Successes: {this.state.ImportResult.successes}<br />
                    <i className='fas fa-times-circle me-2 text-danger'></i>Errors: {this.state.ImportResult.errors}<br />
                    <i className='fas fa-check-circle me-2 text-warning'></i>Existing: {this.state.ImportResult.existing}<br />
                  </>
                }
              </CardBody>
            </Card>
          </ModalBody>
          <ModalFooter>
            <Button color='dark' size='sm' onClick={this.toggleUserImportResult}>Close <i className="far fa-times-circle ms-2"></i></Button>
          </ModalFooter>
        </Modal>
      </>
    );
  }

  async StartImport(evt) {
    evt.stopPropagation();
    let isValid = true;
    isValid &= fieldRequired(this.state.FileData, "errFile", "* Required");

    if (isValid) {
      const bearer = "Bearer " + getAuthToken();
      ThrobbleHelper.toggleThrobble(true, "Importing Users");
      try {
        const response = await fetch("/api/userdata/ImportUsers", {
          method: "POST",
          withCredentials: true,
          credentials: 'include',
          headers: {
            'Authorization': bearer,
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({ FileData: this.state.FileData })
        });

        if (response.ok) {

          const data = await response.json();
          this.loadData();
          this.setState({ ImportResult: data, showUserImport: false, showUserImportResult: true });
          ThrobbleHelper.toggleThrobble(false);

        } else {
          if (response.status === 401)
            window.location.href = "/login";
          else {
            showToast("error", "Users Import", "There was an error importing users");
            ThrobbleHelper.toggleThrobble(false);
          }
        }
      } catch (e) {
        console.error(e);
        showToast("error", "Users Import", "There was an error importing users");
        ThrobbleHelper.toggleThrobble(false);
      }
    }

  }

  handleImportFileChange(evt) {
    const SelectedFile = evt.target.files[0];
    if (SelectedFile) {
      const reader = new FileReader();
      reader.onload = (e) => {
        const FileData = e.target.result;
        this.setState({
          FileData
        });
      }
      reader.readAsDataURL(SelectedFile);
    }
  }

  async loadData() {
    let loading = 3;
    var bearer = 'Bearer ' + getAuthToken();
    try {
      const response = await fetch('api/userdata', {
        method: 'GET',
        withCredentials: true,
        credentials: 'include',
        headers: {
          'Authorization': bearer,
          'Content-Type': 'application/json'
        }
      });
      if (response.ok) {
        const data = await response.json();
        this.setState({ editData: data });
        loading--;
        if (loading <= 0) {
          this.setState({ loading: false });
          ThrobbleHelper.toggleThrobble(false);
        }
      }
      else {
        console.log(response.status + ": " + response.statusText);
        if (response.status === 401)
          window.location.href = "/login";
        ThrobbleHelper.toggleThrobble(false);
      }
    } catch (e) {
      console.error(e);
      ThrobbleHelper.toggleThrobble(false);
    }

    try {
      const SupplierResponse = await fetch("/api/suppliers", {
        method: 'GET',
        withCredentials: true,
        credentials: 'include',
        headers: {
          'Authorization': bearer,
          'Content-Type': 'application/json'
        }
      });

      if (SupplierResponse.ok) {
        const data = await SupplierResponse.json();
        const SupplierList = [{ id: 0, supplierWithEvent: "- All Suppliers -" }, ...data];
        this.setState({ SupplierList});
        loading--;
        if (loading <= 0) {
          this.setState({ loading: false });
          ThrobbleHelper.toggleThrobble(false);
        }
      } else {
        console.log(SupplierResponse.status + ": " + SupplierResponse.statusText);
        if (SupplierResponse.status === 401)
          window.location.href = "/login";

        this.setState({ loading: false });
        ThrobbleHelper.toggleThrobble(false);
      }
    }
    catch (e) {
      console.error("Supplier:", e)
      this.setState({ loading: false });
    }

    try {
      const response = await fetch('api/events', {
        method: 'GET',
        withCredentials: true,
        credentials: 'include',
        headers: {
          'Authorization': bearer,
          'Content-Type': 'application/json'
        }
      });
      if (response.ok) {
        const data = await response.json();
        data.splice(0, 0, {
          "id": 0,
          "name": "- All Events -",
          "startDate": "2020-05-01T12:05:50",
          "endDate": "2034-05-31T12:05:50",
          "status": 1
        });
        //console.log(data);
        this.setState({ allEventData: data});
        loading--;
        if (loading <= 0) {
          this.setState({ loading: false });
          ThrobbleHelper.toggleThrobble(false);
        }
      }
      else {
        console.log(response.status + ": " + response.statusText);
        if (response.status === 401)
          window.location.href = "/login";
      }

    } catch (e) {
      console.error(e);
    }
  }

  async saveData(dataId) {

    this.setState({ loading: true, showError: false, showSuccess: false });

    var bearer = 'Bearer ' + getAuthToken();
    var data = {
      Id: this.state.Id,
      UserId: this.state.UserId,
      UserType: this.state.UserType, FirstName: this.state.FirstName, LastName: this.state.LastName, EmailAddress: this.state.EmailAddress, Mobile: this.state.Mobile,
      CompanyName: this.state.CompanyName, Designation: this.state.Designation, Province: this.state.Province, Password: this.state.Password, SupplierId: this.state.SupplierId, QRImage: this.state.QRImage, LockedOut: this.state.LockedOut,
    }

    try {
      const response = await fetch('api/userdata', {
        method: 'POST',
        withCredentials: true,
        credentials: 'include',
        headers: {
          'Authorization': bearer,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(data),
      });
      if (response.ok) {
        await response.json();
        this.loadData();
        ToastUtility.show({
          title: 'User Data', content: 'The user data was successfully saved!', timeOut: 5000, position: { X: 'Right', Y: 'Top' }, showCloseButton: true, cssClass: 'toast-success'
        });
      }
      else {
        console.log(response.status + ": " + response.statusText);
        if (response.status === 401)
          window.location.href = "/login";
      }

    } catch (e) {
      console.error(e);
      this.setState({ loading: false });
      ToastUtility.show({
        title: 'User Data', content: 'There was an error saving the user data!', timeOut: 5000, position: { X: 'Right', Y: 'Top' }, showCloseButton: true, cssClass: 'toast-danger'
      });
    }
  }

  async deleteData(dataId) {
    this.setState({ loading: true });

    var bearer = 'Bearer ' + getAuthToken();
    try {
      const response = await fetch('api/userdata/' + dataId, {
        method: 'DELETE',
        withCredentials: true,
        credentials: 'include',
        headers: {
          'Authorization': bearer,
          'Content-Type': 'application/json'
        }
      });
      if (response.ok) {
        await response.json();
        this.loadData();
        ToastUtility.show({
          title: 'User Data', content: 'The user data was successfully deleted!', timeOut: 5000, position: { X: 'Right', Y: 'Top' }, showCloseButton: true, cssClass: 'toast-success'
        });
      }
      else {
        console.log(response.status + ": " + response.statusText);
        if (response.status === 401)
          window.location.href = "/login";
      }
    } catch (e) {
      console.error(e);
      this.setState({ loading: false });
      ToastUtility.show({
        title: 'User Data', content: 'There was an error deleting the user data!', timeOut: 5000, position: { X: 'Right', Y: 'Top' }, showCloseButton: true, cssClass: 'toast-danger'
      });
    }
  }
}

