import React, { Component } from 'react';
import { Route, Routes } from 'react-router-dom';
import { Layout } from './components/Layout';
import './css/QRVisitors.css';
import { Home } from './components/Home';
import { Suppliers } from './components/Suppliers';
import { PageContents } from './components/PageContents';
import { UserData } from './components/UserData';
import { Events } from './components/Events';
import UserProfile from './components/UserProfile';
import VisitsReport from './components/EntrantsReport';
import { Spinner } from 'reactstrap';
import Login from './components/login';
import Visitors from './components/Visitors';
import VisitorDetails from './components/VisitorDetails';
import Register from './components/Register';

export default class App extends Component {
  static displayName = App.name;
  static IsLoggedIn = false;


  constructor(props) {
    super(props);

    this.state = {
      loading: true
    }
  }

  componentDidMount() {
    App.IsLoggedIn = false;
    this.setState({ loading: false });
  }

  renderPage() {
    return (
      <Routes>
        <Route exact path='/' element={<Layout />}>
          <Route index element={<Home />} />
          <Route path='/suppliers' element={<Suppliers />} />
          <Route path='/pagecontent' element={<PageContents />} />
          <Route path='/visitors' element={<Visitors />} />
          <Route path='/visitor-details' element={<VisitorDetails />} />
          <Route path='/events' element={<Events />} />
          <Route path='/user-list' element={<UserData />} />
          <Route path='/user-profile' element={<UserProfile />} />
          <Route path='/Visits-report' element={<VisitsReport />} />
        </Route>
        <Route path='/login' element={<Login />} />
        <Route path='/register' element={<Register />} />
      </Routes>
    )
  }

  render() {
    const content = this.state.loading ? <em><Spinner />Loading...</em> : this.renderPage();
    return (
      content
    );
  }
}
