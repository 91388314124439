import { ToastUtility } from '@syncfusion/ej2-notifications';
import { DropDownListComponent } from '@syncfusion/ej2-react-dropdowns';
import { Count, HtmlEditor, Inject, QuickToolbar, RichTextEditorComponent, Toolbar as rteToolbar } from '@syncfusion/ej2-react-richtexteditor';
import React, { Component } from 'react';
import { confirm } from "react-confirm-box";
import { Button, Col, Input, Label, Row } from 'reactstrap';
import { getAuthToken, IsAuthenticated } from '../helpers/authentication';
import ThrobbleHelper from '../helpers/ThrobbleHelper';
import { showToast } from '../helpers/ToastHelper';
import { fieldRequired } from '../helpers/validation';

export class PageContents extends Component {

  constructor(props) {
    super(props);
    const commandTemplate = [
      { type: 'Edit', buttonOption: { cssClass: 'e-flat', iconCss: 'e-edit e-icons' } },
      { type: 'Delete', buttonOption: { cssClass: 'e-flat', iconCss: 'e-delete e-icons' } }
    ];

    this.fullToolbarSettings = {
      items: ['Bold', 'Italic', 'Underline', 'StrikeThrough',
        'FontName', 'FontSize', 'FontColor', 'BackgroundColor',
        'LowerCase', 'UpperCase', '|', 'Formats', 'Alignments', 'OrderedList', 'UnorderedList',
        'Outdent', 'Indent', '|', 'CreateLink', 'Image', '|', 'ClearFormat',
        'SourceCode', 'FullScreen', '|', 'Undo', 'Redo'
      ],
      type: 'Expand'
    };

    this.simpleToolbarSettings = {
      items: ['Bold', 'Italic', 'Underline', '|', 'ClearFormat', 'Undo', 'Redo', '|', 'SourceCode'],
      type: 'Expand'
    };


    this.state = {
      editData: [], loading: true, editModal: false, gridCommands: commandTemplate, gridToolbar: ['Search'],
      Id: 0,
      Slug: '',
      Title: '',
      Copy: '',
      canSave: true

    };
    this.toggle = this.toggle.bind(this);
    this.renderForm = this.renderForm.bind(this);
    this.onItemSelected = this.onItemSelected.bind(this);
  }

  toggle() {
    this.setState({
      editModal: !this.state.editModal
    });
  }

  componentDidMount() {
    IsAuthenticated().then((res) => {
      if (res) {
        document.title = "Est Africa :: Page Contents Administration";
        this.loadData();
      }
    }).catch(() => window.location.href = "/login");
  }

  editItem = (id) => {
    if (id > 0) {
      const data = this.state.editData.find((item) => { return item.Id == id });
      this.setState({ Id: data.Id, Slug: data.Slug, Title: data.Title, Copy: data.Copy, DateAdded: data.DateAdded, AddedBy: data.AddedBy, DateModified: data.DateModified, ModifiedBy: data.ModifiedBy, Status: data.Status, });
    }
    else {
      //clear state fields
      this.setState({
        Id: 0, Id: 0,
        Slug: '',
        Title: '',
        Copy: '',
        DateAdded: new Date(),
        AddedBy: 0,
        DateModified: new Date(),
        ModifiedBy: 0,
        Status: 0,
      });
    }
    this.setState({ editId: id, editModal: true });
  };

  saveItem = async (event) => {
    event.stopPropagation();
    var valid = true;
    valid &= fieldRequired(this.state.Title, "errTitle", "* Required");
    valid &= fieldRequired(this.state.Copy, "errCopy", "* Required");

    if (valid) {
      this.saveData(this.state.editId);
      this.setState({ editModal: false });
    }
  }

  deleteItem = async (id) => {
    const confirmStyles = {
      classNames: {
        confirmButton: 'btn btn-sm confirm-button',
        cancelButton: 'btn btn-sm cancel-button',
      }
    }
    const result = await confirm("Are you sure you want to delete this item?", confirmStyles);
    if (result) {
      this.deleteData(id);
    }
  }

  onItemSelected(evt) {
    const itemDetails = this.state.editData.find(c => c.id === evt.itemData.id);
    if (itemDetails && itemDetails.id > 0) {
      this.setState({ editId: itemDetails.id, Id: itemDetails.id, Title: itemDetails.title, Copy: itemDetails.copy });
    }
  }

  renderForm() {
    return (
      <div className="card mt-4">
        <div className="card-body">
          <div className='row'>
            <div className="col-md-6 mb-3">
              <label>Select Page Content</label>
              <DropDownListComponent fields={{ value: "id", text: "title" }} dataSource={this.state.editData} value={this.state.Id} disabled={true} filterType='Contains' allowFiltering={true} select={this.onItemSelected} />
            </div>
            <div className='col-md-6 mb-4'>
              <Label>Title<span className='ErrorText ms-1'>*</span> <span className="ErrorText" id="errTitle"></span>
              </Label>
              <Input type='text' value={this.state.Title} bsSize={"sm"} onChange={e => this.setState({ Title: e.target.value }, () => fieldRequired(this.state.Title, "errTitle", "Required"))} />
            </div>
            <div className="col-12 mb-3">
              <Label for='tbCopy'>Copy<span className='ErrorText ms-1'>*</span> <span id='errCopy' className='ErrorText' /></Label>
              <RichTextEditorComponent id='tbCopy' name='tbCopy' height={500} toolbarSettings={this.fullToolbarSettings} showCharCount={true} value={this.state.Copy} change={e => this.setState({ Copy: e.value })} >
                <Inject services={[rteToolbar, HtmlEditor, QuickToolbar, Count]} />
              </RichTextEditorComponent>
            </div>
          </div>
          <hr />
          <div className="text-end">
            <Button color='success' size='sm' className='mx-1' onClick={this.saveItem}>Save</Button>
          </div>
        </div>
      </div>
    );
  }

  render() {
    let contents = this.state.loading ? <p className='text-center'><i className='fas fa-spinner fa-spin me-2'></i>Loading...</p> : this.renderForm(this.state.dropDownDataSource);

    return (
      <>
        <div className="container">
          <Row>
            <Col xs={12}>
              <h1>Page Content Administration</h1>
            </Col>
            {/* <Col xs={6} className="text-end align-self-center">
              <Button color="primary" size="sm" data-recordid="0" onClick={this.editItem}>Add New <i className="fas fa-plus-circle ms-2"></i></Button>
            </Col> */}
          </Row>
          <Row>
            <Col xs={12}>
              {contents}
            </Col>
          </Row>
        </div>
      </>
    );
  }

  async loadData() {

    var bearer = 'Bearer ' + getAuthToken();
    try {
      const response = await fetch('api/pagecontents', {
        method: 'GET',
        withCredentials: true,
        credentials: 'include',
        headers: {
          'Authorization': bearer,
          'Content-Type': 'application/json'
        }
      });
      if (response.ok) {
        const data = await response.json();
        const content = data[0];
        if (content && content.id > 0)
          this.setState({ editData: data, editId: content.id, Id: content.id, Title: content.title, Copy: content.copy, loading: false });
        else
          this.setState({ loading: false });
      }
      else {
        console.log(response.status + ": " + response.statusText);
        if (response.status === 401)
          window.location.href = "/login";
      }

    } catch (e) {
      console.error(e);
    }
  }

  async saveData(dataId) {

    this.setState({ loading: true, showError: false, showSuccess: false });

    var bearer = 'Bearer ' + getAuthToken();
    var data = { Id: this.state.Id, Title: this.state.Title, Copy: this.state.Copy }

    try {

      ThrobbleHelper.toggleThrobble(true, "Saving Page Content")
      const response = await fetch('api/pagecontents', {
        method: 'POST',
        withCredentials: true,
        credentials: 'include',
        headers: {
          'Authorization': bearer,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(data),
      });
      if (response.ok) {
        await response.json();
        showToast("success", 'Page Contents', 'The page contents was successfully saved!');
        this.loadData();
      }
      else {
        console.log(response.status + ": " + response.statusText);
        if (response.status === 401)
          window.location.href = "/login";

        this.setState({ loading: false });
        ThrobbleHelper.toggleThrobble(false);
      }

    } catch (e) {
      console.error(e);
      this.setState({ loading: false });
      showToast("success", 'Page Contents', 'There was an error saving the page contents!');
      ThrobbleHelper.toggleThrobble(false);
    }
  }

  async deleteData(dataId) {
    this.setState({ loading: true });

    var bearer = 'Bearer ' + getAuthToken();
    try {
      const response = await fetch('api/pagecontents/' + dataId, {
        method: 'DELETE',
        withCredentials: true,
        credentials: 'include',
        headers: {
          'Authorization': bearer,
          'Content-Type': 'application/json'
        }
      });
      if (response.ok) {
        await response.json();
        this.loadData();
        ToastUtility.show({
          title: 'Page Contents', content: 'The page contents was successfully deleted!', timeOut: 5000, position: { X: 'Right', Y: 'Top' }, showCloseButton: true, cssClass: 'toast-success'
        });
      }
      else {
        console.log(response.status + ": " + response.statusText);
        if (response.status === 401)
          window.location.href = "/login";
      }
    } catch (e) {
      console.error(e);
      this.setState({ loading: false });
      ToastUtility.show({
        title: 'Page Contents', content: 'There was an error deleting the page contents!', timeOut: 5000, position: { X: 'Right', Y: 'Top' }, showCloseButton: true, cssClass: 'toast-danger'
      });
    }
  }
}

