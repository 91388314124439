/* eslint-disable default-case */
import { ColumnDirective, ColumnsDirective, CommandColumn, Edit, GridComponent, Sort, Toolbar } from '@syncfusion/ej2-react-grids';
import { TextBoxComponent } from '@syncfusion/ej2-react-inputs';
import { Inject } from '@syncfusion/ej2-react-richtexteditor';
import { DropDownListComponent } from "@syncfusion/ej2-react-dropdowns";
import React, { Component } from 'react';
import { confirm } from "react-confirm-box";
import { Button, Card, CardBody, Col, Input, Modal, ModalBody, ModalFooter, ModalHeader, Row } from 'reactstrap';
import { getAuthToken, IsAuthenticated } from '../helpers/authentication';
import ThrobbleHelper from '../helpers/ThrobbleHelper';
import { showToast } from '../helpers/ToastHelper';
import { fieldRequired } from '../helpers/validation';

export class Suppliers extends Component {

  constructor(props) {
    super(props);
    const commandTemplate = [
      { type: 'Edit', buttonOption: { cssClass: 'e-flat', iconCss: 'e-edit e-icons' } },
      { type: 'Delete', buttonOption: { cssClass: 'e-flat', iconCss: 'e-delete e-icons' } }
    ];

    this.state = {
      editData: [], eventData: [], allEventData: [], loading: true, editModal: false, gridCommands: commandTemplate, gridToolbar: ['Search'],
      showSupplierImport: false, showSupplierImportResult: false, FileData: "", ImportResult: null,
      Id: 0,
      Name: '',
      EventId: 0,
      EventFilter: 0
    };
    this.toggle = this.toggle.bind(this);
    this.toggleSupplierImport = this.toggleSupplierImport.bind(this);
    this.toggleSupplierImportResult = this.toggleSupplierImportResult.bind(this);
    this.StartImport = this.StartImport.bind(this);
    this.handleImportFileChange = this.handleImportFileChange.bind(this);
  }

  toggle() {
    this.setState({
      editModal: !this.state.editModal
    });
  }

  toggleSupplierImport(evt) {
    evt.stopPropagation();
    this.setState({ showSupplierImport: !this.state.showSupplierImport }, () => console.log(this.state));
  }

  toggleSupplierImportResult(evt) {
    evt.stopPropagation();
    this.setState({ showSupplierImportResult: !this.state.showSupplierImportResult });
  }


  componentDidMount() {
    IsAuthenticated().then((res) => {
      if (res) {
        document.title = "Est Africa :: Suppliers Administration";
        ThrobbleHelper.toggleThrobble(true, "Loading Suppliers")
        this.loadData();
      }
    }).catch(() => window.location.href = "/login");
  }

  editItem = (id) => {
    if (id > 0) {
      const data = this.state.editData.find((item) => { return item.id === id });
      this.setState({ Id: data.id, Name: data.name, EventId: data.eventId });
    }
    else {
      //clear state fields
      this.setState({
        Id: 0,
        Name: '',
        EventId: 0
      });
    }
    this.setState({ editId: id, editModal: true });
  };

  saveItem = async (event) => {
    event.stopPropagation();
    var valid = true;

    if (valid) {
      this.saveData(this.state.editId);
      this.setState({ editModal: false });
    }
  }

  deleteItem = async (id) => {
    const confirmStyles = {
      classNames: {
        confirmButton: 'btn btn-sm confirm-button',
        cancelButton: 'btn btn-sm cancel-button',
      }
    }
    const result = await confirm("Are you sure you want to delete this item?", confirmStyles);
    if (result) {
      this.deleteData(id);
    }
  }

  onGridCommand = (args) => {
    switch (args.commandColumn.type) {
      case 'Edit':
        this.editItem(args.rowData.id);
        break;
      case 'Delete':
        this.deleteItem(args.rowData.id);
        break;
    }
  }

  static renderDataTable(data, gridCommands, gridToolbar, commandClick, EventId) {
    if (EventId > 0)
      data = data.filter(c => c.eventId === EventId);


    return (
      <GridComponent dataSource={data} ref={g => this.grid = g} allowFiltering={true} allowSorting={true} filterSettings={{ type: "Menu" }} commandClick={commandClick} allowSorting={true} toolbar={gridToolbar} >
        <ColumnsDirective>
          <ColumnDirective field='eventName' width='100' headerText="Event" />
          <ColumnDirective field='name' width='100' headerText="Name" />
          <ColumnDirective headerText='Actions' width='100' commands={gridCommands} />
        </ColumnsDirective>
        <Inject services={[Sort, Edit, CommandColumn, Toolbar]} />
      </GridComponent>
    );
  }

  render() {
    //let data = this.state.editData;

    let contents = this.state.loading ? <p className='text-center'><i className='fas fa-spinner fa-spin me-2'></i>Loading...</p> : Suppliers.renderDataTable(this.state.editData, this.state.gridCommands, this.state.gridToolbar, this.onGridCommand, this.state.EventFilter);

    return (
      <>
        <div className="container">
          <Row className='mb-4'>
            <Col xs={12}>
              <h1>Suppliers Administration</h1>
            </Col>
            <Col xs={12}>
              <div className="card">
                <div className="card-body">
                  <Row>
                    <div className="col-md-4">
                      <label>Filter By Event</label>
                      <DropDownListComponent fields={{ value: "id", text: "name" }} placeholder="- All Events -" dataSource={this.state.allEventData} value={this.state.EventFilter} filterType='Contains' allowFiltering={true} select={e => this.setState({ EventFilter: e.itemData.id })} />

                    </div>

                    <div className="col-md-8 text-end  align-self-center">
                      <Button color="dark" size="sm" className="me-2" onClick={this.toggleSupplierImport}>Import Suppliers <i className="fas fa-upload  ms-2"></i></Button>
                      <Button color="primary" size="sm" data-recordid="0" onClick={this.editItem}>Add New <i className="fas fa-plus-circle ms-2"></i></Button>
                    </div>
                  </Row>
                </div>
              </div>
            </Col>

          </Row>

          <Row>
            <Col xs={12}>
              {contents}
            </Col>
          </Row>
        </div>

        <Modal isOpen={this.state.editModal} toggle={this.toggle} className={this.props.className} scrollable size="lg" backdrop="static">
          <ModalHeader toggle={this.toggle} close={<button className="btn-close" onClick={this.toggle}></button>}>Edit Suppliers</ModalHeader>
          <ModalBody>
            <div className="row">
              <div className='mb-3 col-md-5'>
                <DropDownListComponent dataSource={this.state.eventData} fields={{ text: "name", value: "id" }} value={this.state.EventId} floatLabelType='Auto' placeholder="Select Event" select={(e) => this.setState({ EventId: e.itemData.id })} />
                <div id="errEvent" className="ErrorText"></div>
              </div>
              <div className='mb-3 col-md-7'>
                <TextBoxComponent id='tbName' name='tbName' placeholder='Name' type='text' maxLength='250' floatLabelType='Auto' value={this.state.Name} onChange={e => this.setState({ Name: e.value }, () => fieldRequired(e.value, "errName", "* Required"))} /> <div id='tbNameError' className='error-message' />
                <div id="errName" className="ErrorText"></div>
              </div>
            </div>


          </ModalBody>
          <ModalFooter>
            <Button color="dark" size="sm" onClick={this.toggle}>Cancel <i className="far fa-times-circle ms-2"></i></Button>
            <Button color="success" size="sm" onClick={this.saveItem}>Save <i className="far fa-check-circle ms-2"></i></Button>
          </ModalFooter>
        </Modal>


        <Modal isOpen={this.state.showSupplierImport} fade toggle={this.toggleSupplierImport}>
          <ModalHeader toggle={this.toggleSupplierImport} close={<button className="btn-close" onClick={this.toggleSupplierImport}></button>} >
            Import Suppliers
          </ModalHeader>
          <ModalBody>

            <label>Import File <small className='text-muted'>(excel)</small>
              <span id="errFile" className='ErrorText' /></label>
            <Input type='file' accept='.xlsx, .xls' onChange={this.handleImportFileChange} />
          </ModalBody>
          <ModalFooter>
            <Button color='dark' size='sm' onClick={this.toggleSupplierImport}>Cancel <i className="far fa-times-circle ms-2"></i></Button>
            <Button color='success' size='sm' onClick={this.StartImport}>Import <i className="far fa-check-circle ms-2"></i></Button>
          </ModalFooter>
        </Modal>

        <Modal isOpen={this.state.showSupplierImportResult} fade toggle={this.toggleSupplierImportResult}>
          <ModalHeader toggle={this.toggleSupplierImportResult} close={<button className="btn-close" onClick={this.toggleSupplierImportResult}></button>} >
            Supplier Import Results
          </ModalHeader>
          <ModalBody>
            <Card>
              <CardBody>
                {this.state.ImportResult &&
                  <>
                    <i className='fas fa-check-circle me-2 text-success'></i>Successes: {this.state.ImportResult.successes}<br />
                    <i className='fas fa-times-circle me-2 text-danger'></i>Errors: {this.state.ImportResult.errors}<br />
                    <i className='fas fa-check-circle me-2 text-warning'></i>Existing: {this.state.ImportResult.existing}<br />
                  </>
                }
              </CardBody>
            </Card>
          </ModalBody>
          <ModalFooter>
            <Button color='dark' size='sm' onClick={this.toggleSupplierImportResult}>Close <i className="far fa-times-circle ms-2"></i></Button>
          </ModalFooter>
        </Modal>
      </>

    );
  }

  async loadData() {

    var bearer = 'Bearer ' + getAuthToken();
    try {
      const response = await fetch('api/suppliers', {
        method: 'GET',
        withCredentials: true,
        credentials: 'include',
        headers: {
          'Authorization': bearer,
          'Content-Type': 'application/json'
        }
      });
      if (response.ok) {
        const data = await response.json();
        this.setState({ editData: data });
        ThrobbleHelper.toggleThrobble(false);
      }
      else {
        console.log(response.status + ": " + response.statusText);
        if (response.status === 401)
          window.location.href = "/login";
      }

    } catch (e) {
      console.error(e);
    }

    try {
      const response = await fetch('api/events/getActive', {
        method: 'GET',
        withCredentials: true,
        credentials: 'include',
        headers: {
          'Authorization': bearer,
          'Content-Type': 'application/json'
        }
      });
      if (response.ok) {
        const data = await response.json();

        this.setState({ eventData: data, loading: false });
        ThrobbleHelper.toggleThrobble(false);
      }
      else {
        console.log(response.status + ": " + response.statusText);
        if (response.status === 401)
          window.location.href = "/login";
      }

    } catch (e) {
      console.error(e);
    }

    try {
      const response = await fetch('api/events', {
        method: 'GET',
        withCredentials: true,
        credentials: 'include',
        headers: {
          'Authorization': bearer,
          'Content-Type': 'application/json'
        }
      });
      if (response.ok) {
        const data = await response.json();
        data.splice(0, 0, {
          "id": 0,
          "name": "All Events",
          "startDate": "2020-05-01T12:05:50",
          "endDate": "2034-05-31T12:05:50",
          "status": 1
        });
        
        this.setState({ allEventData: data, loading: false });
        ThrobbleHelper.toggleThrobble(false);
      }
      else {
        console.log(response.status + ": " + response.statusText);
        if (response.status === 401)
          window.location.href = "/login";
      }

    } catch (e) {
      console.error(e);
    }
  }

  async saveData(dataId) {

    ThrobbleHelper.toggleThrobble(true, "Saving Supplier")
    var bearer = 'Bearer ' + getAuthToken();
    var data = { Id: this.state.Id, Name: this.state.Name, EventId: this.state.EventId }
    console.log(data);
    try {
      const response = await fetch('api/suppliers', {
        method: 'POST',
        withCredentials: true,
        credentials: 'include',
        headers: {
          'Authorization': bearer,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(data),
      });
      if (response.ok) {
        await response.json();
        showToast(
          "success",
          "Suppliers",
          "The suppliers was successfully saved!"
        );
        this.loadData();
      }
      else {
        console.log(response.status + ": " + response.statusText);
        if (response.status === 401)
          window.location.href = "/login";
        ThrobbleHelper.toggleThrobble(false);
      }

    } catch (e) {
      console.error(e);
      this.setState({ loading: false });
      showToast(
        "error",
        "Suppliers",
        "There was an error saving supplier"
      );
      ThrobbleHelper.toggleThrobble(false);
    }
  }

  async deleteData(dataId) {
    this.setState({ loading: true });

    var bearer = 'Bearer ' + getAuthToken();
    try {
      ThrobbleHelper.toggleThrobble(true, "Deleting Supplier")
      const response = await fetch('api/suppliers/' + dataId, {
        method: 'DELETE',
        withCredentials: true,
        credentials: 'include',
        headers: {
          'Authorization': bearer,
          'Content-Type': 'application/json'
        }
      });
      if (response.ok) {
        showToast(
          "success",
          "Suppliers",
          "The suppliers was successfully deleted!"
        );
        this.loadData();
      }
      else {
        console.log(response.status + ": " + response.statusText);
        if (response.status === 401)
          window.location.href = "/login";
      }
    } catch (e) {
      console.error(e);
      this.setState({ loading: false });
      showToast(
        "error",
        "Suppliers",
        "There was an error deleting supplier"
      );
      ThrobbleHelper.toggleThrobble(false);
    }
  }



  async StartImport(evt) {
    evt.stopPropagation();
    let isValid = true;
    isValid &= fieldRequired(this.state.FileData, "errFile", "* Required");

    if (isValid) {
      const bearer = "Bearer " + getAuthToken();
      ThrobbleHelper.toggleThrobble(true, "Importing Suppliers");
      try {
        const response = await fetch("/api/suppliers/ImportSuppliers", {
          method: "POST",
          withCredentials: true,
          credentials: 'include',
          headers: {
            'Authorization': bearer,
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({ FileData: this.state.FileData })
        });

        if (response.ok) {

          const data = await response.json();
          console.log(data);

          this.loadData();
          this.setState({ ImportResult: data, showSupplierImport: false, showSupplierImportResult: true });
          console.log(this.state.ImportResult);
          ThrobbleHelper.toggleThrobble(false);

        } else {
          if (response.status === 401)
            window.location.href = "/login";
          else {
            showToast("error", "Supplier Import", "There was an error importing Suppliers");
            ThrobbleHelper.toggleThrobble(false);
          }
        }
      } catch (e) {
        console.error(e);
        showToast("error", "Supplier Import", "There was an error importing Suppliers");
        ThrobbleHelper.toggleThrobble(false);
      }
    }

  }

  handleImportFileChange(evt) {
    const SelectedFile = evt.target.files[0];
    if (SelectedFile) {
      const reader = new FileReader();
      reader.onload = (e) => {
        const FileData = e.target.result;
        this.setState({
          FileData
        });
      }
      reader.readAsDataURL(SelectedFile);
    }
  }


}

