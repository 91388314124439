import { ToastUtility } from "@syncfusion/ej2-notifications";
import moment from "moment";
import { showToast } from "./ToastHelper";

export const getAuthToken = () => {
    if (window.localStorage.getItem("AuthToken") != null) {

        var data = JSON.parse(window.localStorage.getItem("AuthToken"));
        //todo: check expiry
        if (IsAuthenticated())
            return data.authToken;
        else
            return "";
    }
    else
        return null;
};

export const setAuthToken = (token, expiry) => {
    const data = { authToken: token, authExpires: expiry }
    try {
        window.localStorage.setItem("AuthToken", JSON.stringify(data));
        window.sessionStorage.setItem("LoginTimestamp", moment().toString());
        return true;
    } catch (e) {
        console.error(e)
        return false;
    }
};

export const removeAuthToken = () => {
    if (window.localStorage.getItem("AuthToken") != null) {
        window.localStorage.removeItem("AuthToken");
        window.localStorage.removeItem("IsPCLogin");
        window.sessionStorage.removeItem("LoginTimestamp");
        return true;
    }
    else
        return false;

};

export const IsAuthenticated = async () => {
    if (window.localStorage.getItem("AuthToken") === null && window.location.pathname !== "/login") {
        window.location.href = "/login"
    }

    const AuthData = window.localStorage.getItem("AuthToken");
    try {
        const response = await fetch("api/login/CheckIsAuthenticated", {
            method: "PUT",
            withCredentials: true,
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json'
            },
            body: AuthData
        });

        if (response.ok) {
            if (window.location.pathname === "/login")
                window.location.href = "/";

            return true
        } else {
            if (response.status === 434) {
                ToastUtility.show({
                    title: "Login",
                    content: "Your login session has expired!",
                    position: { X: "Right", Y: "Top" }
                });
                removeAuthToken();
            }

            if (window.location.pathname !== "/login")
                window.location.href = "/login"
        }
    } catch (e) {
        if (window.location.pathname !== "/login")
            window.location.href = "/login"
    }
    // const data = JSON.parse(window.localStorage.getItem("AuthToken"));
    // const expiryDate = moment(data.authExpires);
    // const now = moment();
    // const diff = expiryDate.diff(now, "minutes");
    // if (diff < 0) {

    //     var loginTimestamp = window.sessionStorage.getItem("LoginTimestamp");
    //     let isLoggedOut = true;

    //     if (loginTimestamp) {
    //         loginTimestamp = moment(loginTimestamp);
    //         let now = moment();
    //         isLoggedOut = now.diff(loginTimestamp, "minutes") < 20;
    //     }

    //     if (!isLoggedOut) {
    //         ToastUtility.show({
    //             title: "Login",
    //             content: "Your login session has expired!",
    //             position: { X: "Right", Y: "Top" }

    //         })

    //         removeAuthToken();
    //         if (window.location.pathname !== "/login")
    //             window.location.href = "/login";
    //     }
    // } else {
    //     window.sessionStorage.setItem("LoginTimestamp", moment().toString());
    //     return true;
    // }


}